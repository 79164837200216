import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from './guards/auth.guard'

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'exams',
    loadChildren: () =>
      import('./pages/exams/exams.module').then(
        (m) => m.ExamsModule
      ),
  },
  {
    path: 'painelExames/:status',
    loadChildren: () =>
      import('./pages/exames/exames.module').then(
        (m) => m.ExamesModule
      ),
  },

  // {
  //   path: 'exams/:code',
  //   loadChildren: () =>
  //     import('./pages/exams/info/info.module').then(
  //       (m) => m.InfoModuleAtendimento
  //     ),
  // },

  {
    path: 'exams/:code/:categoria',
    loadChildren: () =>
      import('./pages/exams/info/info.module').then(
        (m) => m.InfoModuleAtendimento
      ),
  },
  {
    path: 'exams/:code/:categoria/document',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/exams/info/modal-document/modal-document.module').then((m) => m.ModalDocumentModule)
  },


  {
    path: 'clinics',
    loadChildren: () =>
      import('./pages/clinic/clinic.module').then(
        (m) => m.ClinicModule
      ),
  },
  {
    path: 'detail-clinic/:code',
    loadChildren: () =>
      import('./pages/clinic/info/info.module').then(
        (m) => m.InfoModuleClinic
      ),
  },
  {
    path: 'new-clinic',
    loadChildren: () =>
      import('./pages/clinic/info/info.module').then(
        (m) => m.InfoModuleClinic
      ),
  },
  {
    path: 'professionals',
    loadChildren: () =>
      import('./pages/professional/professional.module').then(
        (m) => m.ProfessionalModule
      ),
  },
  {
    path: 'new-professional',
    loadChildren: () =>
      import('./pages/professional/info/info.module').then(
        (m) => m.InfoModule
      ),
  },
  {
    path: 'detail-professional/:code',
    loadChildren: () =>
      import('./pages/professional/info/info.module').then(
        (m) => m.InfoModule
      ),
  },

  {
    path: 'configuracoes',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/configuracoes/configuracoes.module').then(
        (m) => m.ConfiguracoesModule)
  },

  {
    path: 'configuracoes/raca',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/configuracoes/modal-raca/modal-raca.module').then((m) => m.ModalRacatModule)
  },

  {
    path: 'lista-exames',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/lista-exames/lista-exames.module').then(
        (m) => m.ListaExamesModule)
  },
  {
    path: 'editar-exames/:code',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/editar-exames/editar-exames.module').then(
        (m) => m.EditarExamesModule),
  },
  {
    path: 'editar-exames/valores',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/editar-exames/modal-valor/modal-valor.module').then((m) => m.ModalValorModule)
  },
  {
    path: 'editar-exames/parametros',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/editar-exames/modal-parametro/modal-parametro.module').then((m) => m.ModalParametroModule)
  },
  {
    path: 'editar-exames/formulas',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/editar-exames/modal-formula/modal-formula.module').then((m) => m.ModalFormulaModule)
  },

  {
    path: 'cadastro-exames',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/cadastro-exames/cadastro-exames.module').then(
        (m) => m.CadastroExamesModule)
  },

  {
    path: 'atendimento',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/atendimento/atendimento.module').then(
        (m) => m.AtendimentoModule)
  },

  {
    path: 'atendimento',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/atendimento/atendimento.module').then(
        (m) => m.AtendimentoModule)
  },
  {
    path: 'atendimento-triagem/:code',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/atendimento-triagem/atendimento-triagem.module').then(
        (m) => m.AtendimentoTriagemModule)
  },

  {
    path: 'atendimento/proprietario',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/modal-proprietario/modal-proprietario.module').then((m) => m.ModalProprietarioModule)
  },
  {
    path: 'atendimento/pet',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/modal-animal/modal-animal.module').then((m) => m.ModalAnimalModule)
  },
  {
    path: 'profissional-dados',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/profissional-dados/profissional-dados.module').then(
        (m) => m.ProfissionalDadosModule)
  },
  {
    path: 'clinica-dados',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/clinica-dados/clinica-dados.module').then(
        (m) => m.ClinicaDadosModule)
  },
  {
    path: 'clinica-profissionais',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/clinica-profissionais/clinica-profissionais.module').then(
        (m) => m.ClinicaProfissionaisModule)
  },
  {
    path: 'clinica-dados/profissional',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/clinica-profissionais/cadastro-profissional/cadastro-profissional.module').then(
        (m) => m.ModalCadastroProfissionalModule)
  },
  {
    path: 'clinica-dados/editar-profissional',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/clinica-profissionais/editar-profissional/editar-profissional.module').then(
        (m) => m.ModalEditarProfissionalModule)
  },


]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
