import { Component } from '@angular/core'
import { AuthService } from './services/auth.service'
import { SignalRService } from './services/signaR.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'VTD'
  isAuthenticated = false
  constructor(
    private authService: AuthService,
    private signalRService: SignalRService
  ) {
    this.authService.isLoggedIn.subscribe((loggedIn) => {
      if (loggedIn) {
        this.isAuthenticated = loggedIn;
        this.signalRService.initSignalRInstance();

      }
    })
  }
}
