import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { IResponse } from '../models/response'
import { User } from '../models/user'
import { ServerService } from './server.service'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private server: ServerService) {}

  registerUser(params: User) {
    return this.http.post<IResponse>(this.server.url(`/User`), params)
  }

  getUser(idUser: any) {
    return this.http.get<IResponse>(this.server.url(`/User/${idUser}`))
  }

  getLoggedUserInfo(userId: string){
    return this.http.get<IResponse>(this.server.url(`/user/${userId}`)).toPromise();
  }

}
