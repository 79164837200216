import { BrowserModule } from '@angular/platform-browser'
import { LOCALE_ID, NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  NbThemeModule,
  NbLayoutModule,
  NbToastrModule,
  NbSidebarModule,
  NbMenuModule,
  NbDialogModule,
} from '@nebular/theme'
import { NbEvaIconsModule } from '@nebular/eva-icons'

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import localePt from '@angular/common/locales/pt'
import { Interceptor } from './services/intercept.service'
import { AuthGuard } from './guards/auth.guard'
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'
import { JwtModule } from '@auth0/angular-jwt'
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common'
import { NgxMaskModule } from 'ngx-mask'
import { MenuModule } from './components/menu/menu.module'
import { CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask'
import { CustomCurrencyMaskConfig } from './models/currency';
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'

import { ClipboardModule } from 'ngx-clipboard';




registerLocaleData(localePt)

export function tokenGetter() {
  return localStorage.getItem('access_token')
}
@NgModule({
  declarations: [AppComponent, ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    HttpClientModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NgxMaskModule.forRoot(),
    MenuModule,
    CurrencyMaskModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
  ],
  providers: [
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }
