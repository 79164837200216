import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServerService } from './server.service'
import { catchError } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'
import * as jwt_decode from 'jwt-decode'
import { Login } from '../models/login'
import { DataUser } from '../models/user'
import { Router } from '@angular/router'
import { IResponse } from '../models/response'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn = new BehaviorSubject<boolean>(true)

  constructor(
    private http: HttpClient,
    private server: ServerService,
    private router: Router,

    ) {
    this.loggedIn.next(this.getToken() ? true : false)
  }

  loginUser(params: Login) {
    return this.http
      .post<any>(this.server.url(`/Login`), params)
      .pipe(catchError(this.server.handleError))
  }


  forgotPassword(userName: string) {
    return this.http
      .post<any>(this.server.url(`/Login/senha/resetar`), userName)
      .pipe(catchError(this.server.handleError))
  }

  getToken(): string {
    return localStorage.getItem('access-token')
  }

  logout(): void {
    localStorage.clear()
    window.location.reload()
    this.router.navigate(['login'])

  }

  decode() {
    let token = { sid: '', id: '' }
    if (this.getToken()) {
      token = jwt_decode(this.getToken())
      return token
    }
    return token
  }

  refreshToken() {
    const { id } = this.decode()
    console.log(id)
    const refreshToken = localStorage.getItem('refresh-token')


    console.log(refreshToken)
    return this.http
      .post<any>(this.server.url('/Login'), {
        userName: id,
        password: refreshToken,
        grantTypes: 'refresh_token',
        captchaId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        resultado: 0,
      })
      .pipe(catchError(this.server.handleError))
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable()
  }

  getDataUser(): DataUser {
    return JSON.parse(localStorage.getItem('user'))
  }
}
