<ul class="list-group list-group-horizontal" *ngIf="currentUser?.tipoUsuario === 'Gestor'">
  <li class="list-group-item"><a routerLink="/home" routerLinkActive="active">INÍCIO</a></li>
  <li class="list-group-item"><a routerLink="/clinics" routerLinkActive="active">CLÍNICAS</a></li>
  <li class="list-group-item"><a routerLink="/professionals" routerLinkActive="active">PROFISSIONAIS</a></li>
  <li class="list-group-item"><a routerLink="/exams" routerLinkActive="active">EXAMES</a></li>
  <li class="list-group-item">
    <nb-icon icon="menu-outline" (click)="showCard = !showCard"></nb-icon>
    <nb-card class="account-info" style="cursor: default" *ngIf="showCard">
      <nb-card-header class="align-items-center px-3 d-flex">
        <img src="../../../assets/images/perfil-placeholder.jpg" alt="Perfil Image" class="ml-3">
        <div class="header-text ml-3">{{ currentUser?.nome }}</div>
      </nb-card-header>
      <nb-card-body>
        <ul class="px-2">

          <li class="align-items-center">
            <a routerLink="/configuracoes" routerLinkActive="active" class="d-flex align-items-center" (click)="showCard = !showCard">
            <nb-icon icon="settings-outline" class="mr-2 my-3"></nb-icon>
            <div class="list-text"> Configurações </div>
          </a>
          </li>
          <li class="align-items-center">
            <a routerLink="/lista-exames" routerLinkActive="active" class="d-flex align-items-center" (click)="showCard = !showCard">
              <nb-icon icon="clipboard-outline" class="mr-2 my-3"></nb-icon>
              <div class="list-text"> Gerenciar Exames </div>
            </a>

          </li>
          <!-- <li class="align-items-center">
            <nb-icon icon="credit-card-outline" class="mr-2 my-3"></nb-icon>
            <div class="list-text"> Financeiro </div>
          </li> -->
          <li class="align-items-center">
            <nb-icon icon="people-outline" class="mr-2 my-3"></nb-icon>
            <div class="list-text"> Usuários </div>
          </li>
        </ul>
      </nb-card-body>
      <nb-card-footer class="w-100">
        <div class="pointer d-flex align-items-center justify-content-center logout" (click)="logout()">
          <nb-icon icon="log-out-outline" class="mr-2"></nb-icon>
          <div class="text-footer">
            Logoff
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  </li>
</ul>
<ul class="list-group list-group-horizontal" *ngIf="currentUser?.tipoUsuario === 'Profissional'">
  <li *ngFor="let menuItem of menuProfissionais" class="list-group-item"><a routerLink="{{ menuItem.link }}" routerLinkActive="active">{{ menuItem.title }}</a></li>
  <li class="list-group-item">
    <nb-icon icon="menu-outline" (click)="showCard = !showCard"></nb-icon>
    <nb-card class="account-info" style="cursor: default" *ngIf="showCard">
      <nb-card-header class="align-items-center px-3 d-flex">
        <img src="../../../assets/images/perfil-placeholder.jpg" alt="Perfil Image" class="ml-3">
        <div class="header-text ml-3">{{ currentUser?.nome }}</div>
      </nb-card-header>
      <nb-card-body>
        <ul class="px-2">

          <li class="align-items-center">
            <a routerLink="/profissional-dados" routerLinkActive="active" class="d-flex align-items-center" (click)="showCard = !showCard">
            <nb-icon icon="settings-outline" class="mr-2 my-3"></nb-icon>
            <div class="list-text"> Atualizar Dados </div>
          </a>
          </li>
        </ul>
      </nb-card-body>
      <nb-card-footer class="w-100">
        <div class="pointer d-flex align-items-center justify-content-center logout" (click)="logout()">
          <nb-icon icon="log-out-outline" class="mr-2"></nb-icon>
          <div class="text-footer">
            Logoff
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  </li>
</ul>

<ul class="list-group list-group-horizontal" *ngIf="currentUser?.tipoUsuario === 'Clinica'">
  <li *ngFor="let menuItem of menuClinicas" class="list-group-item"><a routerLink="{{ menuItem.link }}" routerLinkActive="active">{{ menuItem.title }}</a></li>
  <li class="list-group-item">
    <nb-icon icon="menu-outline" (click)="showCard = !showCard"></nb-icon>
    <nb-card class="account-info" style="cursor: default" *ngIf="showCard">
      <nb-card-header class="align-items-center px-3 d-flex">
        <img src="../../../assets/images/perfil-placeholder.jpg" alt="Perfil Image" class="ml-3">
        <div class="header-text ml-3">{{ currentUser?.nome }}</div>
      </nb-card-header>
      <nb-card-body>
        <ul class="px-2">

          <li class="align-items-center">
            <a routerLink="/clinica-dados" routerLinkActive="active" class="d-flex align-items-center" (click)="showCard = !showCard">
            <nb-icon icon="settings-outline" class="mr-2 my-3"></nb-icon>
            <div class="list-text"> Atualizar Dados </div>
          </a>
          </li>
        </ul>
      </nb-card-body>
      <nb-card-footer class="w-100">
        <div class="pointer d-flex align-items-center justify-content-center logout" (click)="logout()">
          <nb-icon icon="log-out-outline" class="mr-2"></nb-icon>
          <div class="text-footer">
            Logoff
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  </li>
</ul>
