import { NgModule } from '@angular/core'
import { MenuComponent } from './menu.component'
import { NbSidebarModule, NbMenuModule, NbCardModule, NbIconModule } from '@nebular/theme'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

@NgModule({
  declarations: [MenuComponent],
  imports: [CommonModule, NbSidebarModule, NbCardModule, NbMenuModule, NbIconModule, RouterModule],
  exports: [MenuComponent],
})
export class MenuModule {}
