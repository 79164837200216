import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, take, retry } from 'rxjs/operators'
import { AuthService } from './auth.service'
import { AlertService } from './alert.service'
import { LoadingBarService } from '@ngx-loading-bar/core'

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private alert: AlertService,
    private loadingBar: LoadingBarService
  ) { }
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {




    const newHttpRequest = httpRequest.clone({
      headers: httpRequest.headers.set(
        'Authorization',
        `Bearer ${this.authService.getToken()}`,
      ),
    })



    return next.handle(newHttpRequest).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {

        const { status } = error
        if (status === 401) {


          this.alert.warning(
            'Sua sessão expirou. faça login novamente para continuar.'
          )
          setTimeout(() => {
            this.authService.logout()
          }, 2000)


          // this.tryToAuthorize().then((authorized) => {
          //   console.log('authorized',authorized)
          //   if (authorized) {
          //     this.authService.loggedIn.next(true)
          //     location.reload()
          //   } else {
          //     this.alert.warning(
          //       'Sua sessão expirou. faça login novamente para continuar.'
          //     )
          //     setTimeout(() => {
          //       this.authService.logout()
          //     }, 2000)
          //   }
          // })
        } else if (status === 400) {
          const state = this.loadingBar.useRef('http')
          if ((error.error && error.error.hasError) || error.error) {
            const { errors } = error.error
            console.log(errors)
            this.alert.error(errors[0]);

            // Depois preciso simular request de erro para coloca-los aqui
          }
          state.complete()
        } else {
          return throwError(error)
        }
      })
    )
  }

  async tryToAuthorize(): Promise<boolean> {
    const { value } = await this.authService
      .refreshToken()
      .pipe(take(1))
      .toPromise()
    console.log(value)
    const { token } = value


    if (token) {
      localStorage.setItem('access-token', token.accessToken)
      localStorage.setItem('refresh-token', token.refreshToken)
    }


    return value.authenticated ? true : false
  }
}
