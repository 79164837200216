import { Component, OnInit, OnDestroy } from '@angular/core'
import { NbMenuItem, NbMenuService } from '@nebular/theme'
import { takeWhile } from 'rxjs/operators'
import { DataUser } from 'src/app/models/user'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  private alive = true
  selectedItem: string
  showCard: boolean = false;
  currentUser: DataUser;

  items: NbMenuItem[] = [
    { title: 'Dashboard', icon: 'home-outline', link: 'home' },
    {
      title: 'Painel de Propostas',
      icon: 'home-outline',
      link: 'proposal-panel',
    },

    {
      title: 'Logout',
      icon: 'log-out-outline',
      selected: false,
    },
  ];


  menuAdmin: any = [
    {
      title: 'INICIO',
      link: '/home'
    },
    {
      title: 'CLÍNICAS',
      link: '/clinics'
    },
    {
      title: 'PROFISSIONAIS',
      link: '/professionals'
    },
    {
      title: 'EXAMES',
      link: '/exams'
    },
  ];
  menuClinicas: any = [
    {
      title: 'INICIO',
      link: '/home'
    },
    {
      title: 'EXAMES',
      link: '/exams'
    },
    {
      title: 'PROFISSIONAIS',
      link: '/clinica-profissionais'
    },
  ];
  menuProfissionais: any = [
    {
      title: 'INICIO',
      link: '/home'
    },
    {
      title: 'EXAMES',
      link: '/exams'
    },
  ];


  constructor(
    private menuService: NbMenuService,
    private authService: AuthService,


  ) { }

  ngOnInit() {


    this.currentUser = this.authService.getDataUser();

    // console.log(this.currentUser)


    this.menuService
      .onItemClick()
      .pipe(takeWhile(() => this.alive))
      .subscribe(({ item }) => {
        this.onItemSelection(item)
        this.resetSelectedItem(item.link)
      })
  }

  resetSelectedItem(route: string) {
    this.items.forEach((item) => {
      const { children } = item
      if (children) {
        children.forEach((child: any) => {
          child.selected = route === child.link ? true : false
        })
      }
    })
  }

  onItemSelection({ title }) {
    switch (title) {
      case 'Logout':
        this.logout()
        break
    }
  }

  logout() {
    this.authService.logout()
  }

  ngOnDestroy() {
    this.alive = false
  }
}
