<ngx-loading-bar color="#2ecc71" ref="http" height="4px"></ngx-loading-bar>
<nb-layout>
  <nb-layout-header fixed *ngIf="isAuthenticated" class="px-5 fixedheader">
    <img src="../assets/images/header-logo.png" alt="" width="154px" height="48px">
    <app-menu></app-menu>
  </nb-layout-header>


  <nb-layout-column [ngClass]="{'p-0': !isAuthenticated}">
    <router-outlet></router-outlet>
  </nb-layout-column>

</nb-layout>
