import { NgxGlobalEventsService } from 'ngx-global-events';
import { ActivatedRoute, Router } from "@angular/router"
import { NbDialogService } from "@nebular/theme"
import { DataUser } from "../models/user"
import { AlertService } from "./alert.service"
import { AuthService } from "./auth.service"
import { UserService } from "./user.service"
import { Injectable } from '@angular/core'
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr"


@Injectable({
  providedIn: 'root',
})


export class SignalRService {
  private _hubConnection: HubConnection;

  currentUser: DataUser;
  tipoUsuario: String;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private globalEventsService: NgxGlobalEventsService


  ) {
    this.currentUser = this.authService.getDataUser();
    if (this.currentUser) {
      this.tipoUsuario = this.currentUser.tipoUsuario;
    }


  }



  initSignalRInstance(): void {
    //  if(!this.tipoUsuario) return;
    if (this.tipoUsuario === "Profissional") {
      this.createConnection();
      this.registerOnServerEventsProfissional();
      this.startConnectionProfissional();
    } else if (this.tipoUsuario === "Clinica") {
      this.createConnection();
      this.registerOnServerEventsClinica();
      this.startConnectionClinica();
    }

  }

  connectGroupProfissional(idProfissional: String) {
    console.log(idProfissional);
    this._hubConnection.invoke('ConnectGroupProfissional', idProfissional);
  }
  connectGroupClinica(idClinica: String) {
    console.log("idClinica", idClinica);
    this._hubConnection.invoke('ConnectGroupClinica', idClinica);
  }

  private createConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl('https://apivtd-hom.azurewebsites.net/NotificationHub')
      .build();
  }

  private startConnectionProfissional(): void {
    // console.log(this._hubConnection);
    this._hubConnection
      .start()
      .then(() => {
        console.log('starts connection profissional')
        this.connectGroupProfissional(this.currentUser.id);
      })
      .catch(() => {
        console.log('retries')
        setTimeout(function () { this.startConnectionProfissional(); }, 5000);
      });
  }
  private startConnectionClinica(): void {
    console.log(this._hubConnection);
    this._hubConnection
      .start()
      .then(() => {
        console.log('starts connection clinica')
        this.connectGroupClinica(this.currentUser.id);
      })
      .catch(() => {
        console.log('retries')
        setTimeout(function () { this.startConnectionClinica(); }, 5000);
      });
  }

  private registerOnServerEventsProfissional(): void {
    this._hubConnection.on('NotificarProfissional', (data: any) => {
      this.globalEventsService.get("NotificarProfissional").emit(data);
    });
  }
  private registerOnServerEventsClinica(): void {
    console.log("Clinica Registrou em eventos1")
    this._hubConnection.on('NotificarClinica', (data: any) => {
      console.log(data)
      this.globalEventsService.get("NotificarClinica").emit(data);
    });
    this._hubConnection.on('SucessoUploadAnexo', (data: any) => {
      console.log(data)
      this.globalEventsService.get("SucessoUploadAnexo").emit(data);
    });
    this._hubConnection.on('FalhaUploadAnexo', (data: any) => {
      console.log(data)
      this.globalEventsService.get("FalhaUploadAnexo").emit(data);
    });
  }
}
